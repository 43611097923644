import React from 'react';
import styled from 'styled-components';
import VideoBackground from '../components/VideoBackground';

const Home = () => {

  return (
    <HomeWrapper id="HomeWrapper">
      <VideoBackground id="VideoBackground" />
        <BuyTicketsButton id="BuyTicketsButton" href="https://wa.me/5511982136000" target="_blank" >Saiba Mais...
      </BuyTicketsButton>
    </HomeWrapper>
  );
};

const HomeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 5;
`;

const BuyTicketsButton = styled.a`
  position: absolute;
  bottom: 40px;
  right: 20px;
  padding: 12px 20px;
  font-size: 17px;
  background-color: ${(props) => props.theme.colors.flashred1};
  color: #fff;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  @media (max-width: 768px){
    bottom: 30px;
    right: 20px;
    padding: 10px 16px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.flashred1};
    color: #fff;
    border-radius: 12px;
  }
  @media (max-width: 460px){
    bottom: 30px;
    right: 20px;
    padding: 10px 10px;
    font-size: 14px;
    background-color: ${(props) => props.theme.colors.flashred1};
    color: #fff;
    border-radius: 12px;
  }
`;

export default Home;



