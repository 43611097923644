// eslint-disable-next-line
export default {
  title: 'variables',

  colors: {
    // RED:
    IndianRed: '#CD5C5C',
    LightCoral: '#F08080',
    Salmon: '#FA8072',
    DarkSalmon: '#E9967A',
    LightSalmon: '#FFA07A',
    Crimson: '#DC143C',
    Red: '#FF0000',
    FireBrick: '#B22222',
    DarkRed: '#8B0000',

    // Pink:
    Rosa: '#FFC0CB',
    LightPink: '#FFB6C1',
    HotPink: '#FF69B4',
    DeepPink: '#FF1493',
    MediumVioletRed: '#C71585',
    PaleVioletRed: '#DB7093',

    // Orange:
    Coral: '#FF7F50',
    Tomato: '#FF6347',
    OrangeRed: '#FF4500',
    DarkOrange: '#FF8C00',
    Orange: '#FFA500',

    // Yellow:
    Yellow: '#FFFF00',
    LightYellow: '#FFFFE0',
    LemonChiffon: '#FFFACD',
    LightGoldenrodYellow: '#FAFAD2	',
    PapayaWhip: '#FFEFD5',
    Moccasin: '#FFE4B5',
    PeachPuff: '#FFDAB9',
    PaleGoldenrod: '#EEE8AA',
    Khaki: '#F0E68C',
    DarkKhaki: '#BDB76B',
    YellowColor01: '#ffb070',
    YellowColor02: '#ffb142',

    // Purple:
    Lavender: '#E6E6FA',
    Thistle: '#D8BFD8',
    Plum: '#DDA0DD',
    Violet: '#EE82EE',
    Orchid: '#DA70D6',
    Fuchsia: '#FF00FF',
    Magenta: '#FF00FF',
    MediumOrchid: '#BA55D3',
    MediumPurple: '#9370DB',
    BlueViolet: '#8A2BE2',
    DarkViolet: '#9400D3',
    DarkOrchid: '#9932CC',
    DarkMagenta: '#8B008B',
    Purple: '#800080',
    RebeccaPurple: '#663399',
    Indigo: '#4B0082',
    MediumSlateBlue: '#7B68EE',
    DarkSlateBlue: '#483D8B',

    // Greens:
    GreenYellow: '#ADFF2F',
    Chartreuse: '#7FFF00',
    LawnGreen: '#7CFC00',
    Lime: '#00FF00',
    LimeGreen: '#32CD32',
    PaleGreen: '#98FB98',
    LightGreen: '#90EE90',
    MediumSpringGreen: '#00FA9A',
    SpringGreen: '#00FF7F',
    MediumSeaGreen: '#3CB371',
    SeaGreen: '#2E8B57',
    ForestGreen: '#228B22',
    Green: '#008000',
    DarkGreen: '#006400',
    YellowGreen: '#9ACD32',
    OliveDrab: '#6B8E23',
    Olive: '#808000',
    DarkOliveGreen: '#556B2F',
    MediumAquamarine: '#66CDAA',
    DarkSeaGreen: '#8FBC8F',
    LightSeaGreen: '#20B2AA',
    DarkCyan: '#008B8B',

    // Blues:
    Aqua: '#00FFFF',
    Cyan: '#00FFFF',
    LightCyan: '#E0FFFF',
    PaleTurquoise: '#AFEEEE',
    Aquamarine: '#7FFFD4',
    Turquoise: '#40E0D0',
    MediumTurquoise: '#48D1CC',
    DarkTurquoise: '#00CED1',
    CadetBlue: '#5F9EA0',
    SteelBlue: '#4682B4',
    LightSteelBlue: '#B0C4DE',
    PowderBlue: '#B0E0E6',
    LightBlue: '#ADD8E6',
    SkyBlue: '#87CEEB',
    LightSkyBlue: '#87CEFA',
    DeepSkyBlue: '#00BFFF',
    DodgerBlue: '#1E90FF',
    CornflowerBlue: '#6495ED',
    RoyalBlue: '#4169E1',
    Blue: '#0000FF',
    MediumBlue: '#0000CD',
    DarkBlue: '#00008B',
    Navy: '#000080',
    MidnightBlue: '#191970',
    SlateBlue: '#6A5ACD',
    SlateBlue1: '#836FFF',
    SlateBlue3: '#6959CD',
    AquaCyan: '#00FFFF',
    BlueColor01: '#4b59f7',

    // Browns:
    Cornsilk: '#FFF8DC',
    BlanchedAlmond: '#FFEBCD',
    Bisque: '#FFE4C4',
    NavajoWhite: '#FFDEAD',
    Wheat: '#F5DEB3',
    BurlyWood: '#DEB887',
    Tan: '#D2B48C',
    RosyBrown: '#BC8F8F',
    SandyBrown: '#F4A460',
    Goldenrod: '#DAA520',
    DarkGoldenrod: '#B8860B',
    Peru: '#CD853F',
    Chocolate: '#D2691E',
    SaddleBrown: '#8B4513',
    Sienna: '#A0522D',
    Brown: '#A52A2A',
    Maroon: '#800000',

    // Whites:
    White: '#FFFFFF',
    Snow: '#FFFAFA',
    Honeydew: '#F0FFF0',
    MintCream: '#F5FFFA',
    Azure: '#F0FFFF',
    AliceBlue: '#F0F8FF',
    GhostWhite: '#F8F8FF',
    WhiteSmoke: '#F5F5F5',
    Seashell: '#FFF5EE',
    Beige: '#F5F5DC',
    OldLace: '#FDF5E6',
    FloralWhite: '#FFFAF0',
    Ivory: '#FFFFF0',
    AntiqueWhite: '#FAEBD7',
    Linen: '#FAF0E6',
    LavenderBlush: '#FFF0F5',
    MistyRose: '#FFE4E1',

    // Grays/Greys:
    Gainsboro: '#DCDCDC',
    LightGray: '#D3D3D3',
    LightGrey: '#D3D3D3',
    Silver: '#C0C0C0',
    DarkGray: '#A9A9A9',
    DarkGrey: '#A9A9A9',
    Gray: '#808080',
    Grey: '#808080',
    DimGray: '#696969',
    DimGrey: '#696969',
    LightSlateGray: '#778899',
    LightSlateGrey: '#778899',
    SlateGray: '#708090',
    SlateGrey: '#708090',
    DarkSlateGray: '#2F4F4F',
    DarkSlateGrey: '#2F4F4F',
    Grey21: '#363636',
    Grey31: '#4F4F4F',
    GreyBack: '#323232',

    Green01: '#66bb6a',
    Green02: '#43a047',
    Green03: '#3FB984',
    Green04: '#085833',
    Green05: '#001d0d',
    Light: '#f5f5f5',
    Sand: '#f0eae2',
    Sandgold: '#e9d9c5',
    Goldlight: '#d4c2a6',
    Goldmd: '#c5a77b',
    Gold: '#c59d5f',

    Redhover: '#aa2020',
    Reddark: '#bb2525',
    RedOrange: '#f01027',
    Redmd: '#FF5555',
    Redlight: '#e66b6b',
    Greendark: '#0b9316',
    Greenlight: '#6be675',
    Pink: '#d61eed',

    Black: '#000',
    BlackColor01: '#101522',
    BlackColor02: '#151517',
    Lightshadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
    Darkshadow: '0 5px 15px rgba(0, 0, 0, 0.2)',

    // Opacity:
    Backopacity1: 'rgba(0, 0, 0, 0.1)',
    Blackopacity2: 'rgba(0, 0, 0, 0.2)',
    Blackopacity25: 'rgba(0, 0, 0, 0.25)',
    Blackopacity3: 'rgba(0, 0, 0, 0.3)',
    Blackopacity4: 'rgba(0, 0, 0, 0.4)',
    Blackopacity5: 'rgba(0, 0, 0, 0.5)',
    Blackopacity6: 'rgba(0, 0, 0, 0.6)',
    Blackopacity7: 'rgba(0, 0, 0, 0.7)',
    Blackopacity8: 'rgba(0, 0, 0, 0.8)',
    Blackopacity9: 'rgba(0, 0, 0, 0.9)',
    Grayopacity: 'rgba(51, 51, 51, 0.6)',
    Whiteopacity1: 'rgba(255, 255, 255, 0.1)',
    Whiteopacity2: 'rgba(255, 255, 255, 0.2)',
    Whiteopacity3: 'rgba(255, 255, 255, 0.3)',
    Whiteopacity4: 'rgba(255, 255, 255, 0.4)',
    Whiteopacity5: 'rgba(255, 255, 255, 0.5)',
    Whiteopacity6: 'rgba(255, 255, 255, 0.6)',
    Whiteopacity7: 'rgba(255, 255, 255, 0.7)',
    Whiteopacity8: 'rgba(255, 255, 255, 0.8)',
    Whiteopacity9: 'rgba(255, 255, 255, 0.9)',
    Yellowopacity01: 'rgba(255, 176, 112, 0.7)',
    Yellowopacity02: 'rgba(255, 176, 112, 0.3)',

    flashdark: '#050014',
    flashdark2: '#050018',
    flashdark3: '#393747',
    flashdark4: '#56526e',
    flashdark5: '#9b96b5',
    flashred1: '#c6252b',
    flashred2: '#ac3a47',
    flashgold1: '#ffeb7b',
    flashgold2: '#fffed9',
    flashgreen: '#20f507',
  },
}

