import { styled, ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import GlobalStyle from './styles/global';
import variables from './styles/themes/variables';

import { Home } from './pages';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <AppContainer id="AppContainer">
            <ThemeProvider id="ThemeProvider" theme={variables}>
            <Home id="Home" data="Home" />

            <GlobalStyle data="GlobalStyle" />
            
            </ThemeProvider>
          </AppContainer>} />
        <Route />
      </Routes>
    </BrowserRouter>
    
  );
}

const AppContainer = styled.div`
  position: relative;
`

export default App;
