import React from 'react';
import styled from 'styled-components';

import Large from '../assets/images/large.png'
import Medium from '../assets/images/medium.png'
import Small from '../assets/images/mobile.png'




const VideoBackground = ({ videoUrl }) => {
  return (
    <VideoBackgroundWrapper id="VideoBackgroundWrapper" >
      
      <ImageBack large={Large} medium={Medium} mobile={Small} />

    </VideoBackgroundWrapper>
  );
};



const VideoBackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: 600px){
  }
  @media (min-width: 768px){
  }
`
const ImageBack = styled.div`
  margin: 0 auto;
  width: 100vw;
  // background for mobile
  background-image: url(${(props) => props.large});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;

  @media (max-width: 768px) {
    // background for mobile
    background-image: url(${(props) => props.medium});
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
  }

  @media (max-width: 460px) {
    // background for mobile
    background-image: url(${(props) => props.mobile});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export default VideoBackground;
