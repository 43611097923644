import { createGlobalStyle } from 'styled-components'
import EvolveBold from '../assets/fonts/Evolve_Sans_Bold.otf';
import EvolveMedium from '../assets/fonts/Evolve_Sans_Medium.otf';
import EvolveRegular from '../assets/fonts/Evolve_Sans_Regular.otf';

export default createGlobalStyle`
	@font-face {
		font-family: 'EvolveBold';
		src: url(${EvolveBold}) format('opentype');
	}
	@font-face {
		font-family: 'EvolveMedium';
		src: url(${EvolveMedium}) format('opentype');
	}
	@font-face {
		font-family: 'EvolveRegular';
		src: url(${EvolveRegular}) format('opentype');
	}
	
	*,
  html {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}
	::after,
	::before {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	ul {
		list-style-type: none;
	}
	a {
		text-decoration: none;
	}
	body,
	html {
			min-width: 360px;
			background-color: ${(props) => props.theme.colors.Black};
			&::-webkit-scrollbar{
		display: none;
		}
	}
	body {
    overflow: ${(props) => (props.isModalOpen ? 'hidden' : 'auto')};
  }
`
